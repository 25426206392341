import axios from "axios";

const service = axios.create({
  // baseURL 需要设置为反向代理前缀，不能设置绝对路径URL
  baseURL: "/api",
  timeout: 30000,
  withCredentials: false,
});
// 添加请求拦截器

service.interceptors.request.use(
  function (req) {
    service.defaults.headers.get["Content-Type"] =
      "application/json;charset=UTF-8";
    service.defaults.headers.post["Content-Type"] =
      "application/json;charset=UTF-8";
    return req;
  },
  function (error) {
    return Promise.reject({ error });
  }
);
//添加响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 200 || res.status !== "success") {
      // 若后台返回错误值，此处返回对应错误对象，下面 error 就会接收
      this.$message.error(response.msg);
      return Promise.reject(new Error(response.data.msg || "Error"));
    }
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误(400)";
          break;
        case 401:
          error.message = "未授权,请登录(401)";
          break;
        case 403:
          error.message = "拒绝访问(403)";
          break;
        case 404:
          break;
        case 405:
          error.message = "请求方法未允许(405)";
          break;
        case 408:
          error.message = "请求超时(408)";
          break;
        case 500:
          break;
        default:
          error.message = `连接错误: ${error.message}`;
      }
      return Promise.reject(error);
    }
  }
);

export default service;
