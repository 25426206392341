import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import dataV from "@jiaminghi/data-view";
import { createPinia, PiniaVuePlugin } from "pinia";

import * as echarts from "echarts";

Vue.prototype.$echarts = echarts;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;

// swiper
import "swiper/css/swiper.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import request from "@/util/request";
Vue.prototype.$request = request;

Vue.use(dataV);
Vue.use(ElementUI);
new Vue({
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
